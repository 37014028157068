<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo" href="/">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Testudy.io
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Your adventure with Testudy starts here 👇
          </b-card-title>

          <b-alert :variant="variantForAccount" show v-if="msgToUser" >
            <div class="alert-body font-small-2">
              <p><small class="mr-50"><span class="font-weight-bold">{{ msgToUser }}</span></small></p>
            </div>
          </b-alert>       

          <b-card-text class="mb-2" v-if="!msgToUser" >
            Your email must be valid. To finish registraton, click on the activation link in the letter on your inbox
          </b-card-text>             

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          > 
          <!-- @submit.prevent="validate" -->
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- name -->
              <b-form-group
                label="Your name"
                label-for="register-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Your name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="register-name"
                    v-model="name"
                    name="register-name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required:true|min:5"

                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="register-password2"
                label="Confirm password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="confirmpassword"
                  rules="required:true|min:5|confirmed:password"

                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password2"
                      v-model="password2"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password2"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>              
              

              <b-form-group>


                <validation-provider
                  #default="{ errors }"
                  name="I agree"
                  vid="iagree"
                  rules="required:true"
                >                

                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link href="https://www.testudy.io/terms-and-conditions" target="_blank">privacy policy & terms</b-link>
                </b-form-checkbox>
              
              </validation-provider>


              </b-form-group>

              <b-form-group>

                <vue-recaptcha
                ref="recaptcha"
                size="invisible"
                :sitekey="sitekey"
                @verify="register"
                @expired="onCaptchaExpired"
              />

                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid"
                >
                  Sign up
                </b-button>
              </b-form-group>  
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              Need help?
            </div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="https://www.facebook.com/profile.php?id=100086191706022" target="_blank" title="Facebook group">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="instagram" href="https://www.instagram.com/testudyio/" target="_blank" title="Instagram">
              <feather-icon icon="InstagramIcon" />
            </b-button>             
            <b-button variant="twitter" href="https://twitter.com/testudyio" target="_blank" title="Twitter">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="mailto:info@testudy.io?subject=Feedback from my.testudy.io" title="Email us">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="secondary" href="https://www.testudy.io/" target="_blank" title="www.testudy.io">
              <feather-icon icon="GlobeIcon" />
            </b-button>
            <b-button variant="warning" href="https://www.testudy.io/#contact" target="_blank" title="Contact us">
              <feather-icon icon="Edit3Icon" />
            </b-button>           
          </div>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BAlert
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  components: { VuexyLogo, BRow, BImg, BCol, BLink, BButton, BForm, BCardText, BCardTitle, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend,  
      ValidationProvider, ValidationObserver, VueRecaptcha, BAlert
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      name: '',
      userEmail: '',
      password: '',
      password2: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      sitekey: process.env.VUE_APP_RECAPTCHA_TOKEN,
      captchaTryCount: 0,
      msgToUser: '',
      msgIsDanger: false      
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    variantForAccount() {
      return this.msgIsDanger == true ? 'danger' : 'success';
    }     
  },

  methods: {
    register(recaptchaToken) {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt.register({
            name: this.name,
            email: this.userEmail,
            password: this.password,
            recaptcha: recaptchaToken,
          })
            .then(response => {
              // console.log('recaptchaToken1')
              // console.log(recaptchaToken)
              // // useJwt.setToken(response.data.accessToken)
              // useJwt.setToken(response.data.access)
              // // useJwt.setRefreshToken(response.data.refreshToken)
              // useJwt.setRefreshToken(response.data.refresh)

              // // testudy back give 'access', not 'accessToken'
              // userData.accessToken = response.data.access
              // delete response.data.access
              
              // // testudy back give 'refresh', not 'refreshToken'
              // userData.refreshToken = response.data.refresh               
              // delete response.data.refresh 

              // userData.ability = [
              //     {
              //       action: 'manage',
              //       subject: 'all',
              //     },
              //   ],

              // localStorage.setItem('userData', JSON.stringify(response.data.userData))
              // // this.$ability.update(response.data.userData.ability)
              if (response.status === 429) {
                if (this.captchaTryCount <=2) {
                  this.$refs.recaptcha.execute(); 
                }
                this.captchaTryCount +=1 
              } else {
                this.$router.push({ name: 'auth-login', 
                                    params: {
                                      msgToUser: 'Great, you just need to activate your account now. We sent a letter with instructions to your email ' + this.userEmail + '. After you are done, log in again' 
                                    } })
              }
            })
            .catch(error => {
              // console.log('recaptchaToken2')
              // console.log(recaptchaToken)

              //this.$refs.registerForm.setErrors(error.response.data.error)
              // console.log('error.status')
              // console.log(error.status)
              this.msgIsDanger = true;
              if (error.status === 429) {
                if (this.captchaTryCount <=2) {
                  this.$refs.recaptcha.execute(); 
                }
                this.captchaTryCount +=1 
                // this.$refs.registerForm.setErrors(error.data); 
                this.msgToUser = error.data['email'];
              } else {
                // this.$refs.registerForm.setErrors(error.data); 
                this.msgToUser = error.data['email'];
              }
            })
        }
      })
    },
    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    },
    // validate () {
    //   const self = this
    //   self.$validator.validateAll().then((result) => {
    //     if (result) {
    //       self.$refs.recaptcha.execute()
    //     }
    //   })
    // },

  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
